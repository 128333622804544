import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { aracoinAPI } from "../Common";
import Swal from 'sweetalert2';
import qs from 'qs';

const Index = () => {
  const Navigate = useNavigate();
  const [user, setUserData] = useState({
    email: "",
    emailErr: "",
    validEmail: "",
    password: "",
    passwordErr: "",
    loaderAct: false
  })

  const identical = (myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      for (var j = 0; j < myArray.length; j++) {
        if (i !== j) {
          if (myArray[i] === myArray[j]) {
            return true; // means there are duplicate values
          }
        }
      }
    }
    return false; // means there are no duplicate values.
  }

  const handleOnChangePassword = (event) => {
    if (event.target.value === "") {
      setUserData(userData => {
        return {
          ...userData,
          passwordErr: "** This field is required",
        }
      })
    } else {
      setUserData(userData => {
        return {
          ...userData,
          password: event.target.value,
          passwordErr: "",
        }
      })
    }
  }

  const handleOnChangeEmail = (event) => {
    setUserData(userData => {
      return {
        ...userData,
        email: event.target.value,
      }
    })
    let emailIDVal = /^(?!.{255})(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let emailIDTrue = emailIDVal.test(event.target.value);
    if (emailIDTrue) {
      let email1 = event.target.value.split("@")
      let email2 = email1[email1.length - 1].split(".")
      if (email2.length < 4) {
        let email3 = identical(email2)
        if (!email3) {
          setUserData(userData => {
            return {
              ...userData,
              validEmail: true,
              email: event.target.value,
              emailErr: "",
            }
          })
        }
        else {
          setUserData(userData => {
            return {
              ...userData,
              emailErr: "** Invalid Domain Names",
            }
          });
        }
      }
      else {
        setUserData(userData => {
          return {
            ...userData,
            emailErr: "** Invalid Domain Names",
          }
        });
      }

    } else {
      if (event.target.value === "") {
        setUserData(userData => {
          return {
            ...userData,
            validEmail: false,
            emailErr: "** This field is required",
          }
        });
      }
      else {
        setUserData(userData => {
          return {
            ...userData,
            validEmail: false,
            emailErr: "** Invalid Email",
          }
        });
      }
    }
  }

  const submitOnEnter = (event) => {
    if (event.key === "Enter") {
      login();
    }
  }

  const login = async () => {
    try {
      if (user.email !== '' && user.email !== undefined) {
        if (user.password !== '' && user.password !== undefined) {
          if (user.validEmail) {
            setUserData(userData => {
              return {
                ...userData,
                loaderAct: true,
              }
            });
            let data = qs.stringify({
              'email': user.email,
              'password': user.password
            });
            let response = await aracoinAPI(data, 'POST', 'login');
            if (response.success == true) {
              sessionStorage.setItem("id", response.data[0].id);
              Swal.fire({ text: response.msg, icon: 'success', background: 'white', confirmButtonText: false, showConfirmButton: false, timer: 3000 });
              setTimeout(function () {
                Navigate('/Info');
              }, 3000);
            } else {
              Swal.fire({ text: response.msg, icon: 'error', background: 'white', confirmButtonText: false, showConfirmButton: false, timer: 3000 });
            }
          } else {
            setUserData(userData => {
              return {
                ...userData,
                emailErr: `** Please include an '@' in the email address. ${user.email} is missing an '@'`,
              }
            });
          }

        } else {
          setUserData(userData => {
            return {
              ...userData,
              passwordErr: "** This field is required",
            }
          });
        }

      } else {
        setUserData(userData => {
          return {
            ...userData,
            emailErr: "** This field is required",
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <section className="bgImages">
        <div className="container-fluid">
          <div className="row  Average-chart mt-4 mb-5">
            <div className="col-md-12">
              <div className="headerpage">
                <h2 style={{ color: "#fff" }}>Login</h2>
              </div>
              <div className="FormBOx">
                <div>
                  <label>Email:</label>
                  <input type="text" placeholder="Enter the email address" value={user.email} onChange={handleOnChangeEmail} onKeyUp={submitOnEnter} />
                  <div>
                    <span style={{ color: "red" }} >{user.emailErr}</span>
                  </div>
                </div>
                <div>
                  <label>Password:</label>
                  <input type="password" placeholder="Enter the password" value={user.password} onChange={handleOnChangePassword} onKeyUp={submitOnEnter} />
                  <div>
                    <span style={{ color: "red" }} >{user.passwordErr}</span>
                  </div>
                </div>
                <div className="btnsubmit text-center">
                  {user.loaderAct ? <button>Processing....</button> : <button onClick={login}>Login</button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
