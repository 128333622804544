import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import { aracoinAPI } from "../Common";
import Swal from "sweetalert2";
import qs from 'qs';

const Index = () => {
    const Navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [openForAdd, setOpenForAdd] = React.useState(false);
    const [items, setItem] = useState([]);
    const [showItems, setShowItems] = useState(10);
    const [showAll, setShowAll] = useState(false);
    const [exchangeData, setExchangeData] = useState({
        id: "",
        name: "",
        link: "",
        nameErr: "",
        linkErr: "",
        loaderAct: false
    })

    const [addExchangeData, setAddExchangeData] = useState({
        name: "",
        link: "",
        nameErr: "",
        linkErr: "",
        loaderAct1: false
    })

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseForAdd = () => {
        setOpenForAdd(false);
    };

    const handleOpen = (name, link, id) => {
        setOpen(true);
        setExchangeData(data => {
            return {
                ...data,
                id: id,
                name: name,
                link: link,
            }
        })
    };

    const handleOpenforAdd = (name, link) => {
        setOpenForAdd(true);
        setAddExchangeData(data => {
            return {
                ...data,
                name: name,
                link: link,
            }
        })
    };

    const handleClick = () => {
        if (showAll) {
            setShowItems(10);
            setShowAll(false);
        } else {
            setShowItems((prevShowItems) => prevShowItems + 10);
            if (showItems + 10 >= items.length) {
                setShowAll(true);
            }
        }
    };

    const viewExchange = async () => {
        try {
            const fetchInfo = await aracoinAPI('', "GET", 'view-exchange');
            if (fetchInfo.success) {
                setItem(fetchInfo.data);
            }
        } catch (err) {
            console.error(err)
        }
    }
    viewExchange();

    const deleteExchange = async (id) => {
        try {
            let data = qs.stringify({
                'id': id,
                'adminId': sessionStorage.getItem("id")
            });
            const response = await aracoinAPI(data, "POST", 'delete-exchange');
            if (response.success == true) {
                Swal.fire({ text: response.msg, icon: 'success', background: 'white', confirmButtonText: false, showConfirmButton: false, timer: 3000 });
            } else {
                Swal.fire({ text: response.msg, icon: 'error', background: 'white', confirmButtonText: false, showConfirmButton: false, timer: 3000 });
            }
        } catch (err) {
            console.error(err)
        }
    }

    const handleOnChangeName = (event) => {
        if (event.target.value === "") {
            setExchangeData(data => {
                return {
                    ...data,
                    nameErr: "** This field is required."
                }
            })
        } else {
            setExchangeData(userData => {
                return {
                    ...userData,
                    name: event.target.value,
                    nameErr: "",
                }
            })
        }
    }

    const handleOnChangeNameInAdd = (event) => {
        if (event.target.value === "") {
            setAddExchangeData(data => {
                return {
                    ...data,
                    nameErr: "** This field is required."
                }
            })
        } else {
            setAddExchangeData(userData => {
                return {
                    ...userData,
                    name: event.target.value,
                    nameErr: "",
                }
            })
        }
    }

    const handleOnChangeLink = (event) => {
        if (event.target.value === "") {
            setExchangeData(data => {
                return {
                    ...data,
                    linkErr: "** This field is required."
                }
            })
        } else {
            setExchangeData(userData => {
                return {
                    ...userData,
                    link: event.target.value,
                    linkErr: "",
                }
            })
        }
    }

    const handleOnChangeLinkInAdd = (event) => {
        if (event.target.value === "") {
            setAddExchangeData(data => {
                return {
                    ...data,
                    linkErr: "** This field is required."
                }
            })
        } else {
            setAddExchangeData(userData => {
                return {
                    ...userData,
                    link: event.target.value,
                    linkErr: "",
                }
            })
        }
    }

    const submitOnEnter = (event) => {
        if (event.key === "Enter") {
            updateExchange();
            addExchange();
        }
    }

    const updateExchange = async () => {
        if (exchangeData.name !== "" && exchangeData.name !== undefined) {
            if (sessionStorage.getItem("id") !== "" && sessionStorage.getItem("id") !== undefined && sessionStorage.getItem("id") !== null) {
                if (exchangeData.link !== "" && exchangeData.link !== undefined) {
                    setExchangeData(userData => {
                        return {
                            ...userData,
                            loaderAct: true,
                        }
                    });
                    let data = qs.stringify({
                        'name': exchangeData.name,
                        'link': exchangeData.link,
                        'id': exchangeData.id,
                        'adminId': sessionStorage.getItem("id")
                    });
                    const response = await aracoinAPI(data, "POST", 'update-exchange');
                    if (response.success == true) {
                        setOpen(false);
                        Swal.fire({ text: response.msg, icon: 'success', background: 'white', confirmButtonText: false, showConfirmButton: false, timer: 3000 });
                        setExchangeData(userData => {
                            return {
                                ...userData,
                                id: "",
                                name: "",
                                link: "",
                                nameErr: "",
                                linkErr: "",
                                loaderAct: ''
                            }
                        })

                    } else {
                        Swal.fire({ text: response.msg, icon: 'error', background: 'white', confirmButtonText: false, showConfirmButton: false, timer: 3000 });
                    }
                } else {
                    setExchangeData(data => {
                        return {
                            ...data,
                            linkErr: "** This field is required."
                        }
                    })
                }
            } else {
                Swal.fire({ text: "You are not a authorized user.", icon: 'warning', background: 'white', confirmButtonText: false, showConfirmButton: false, timer: 3000 });
            }

        } else {
            setExchangeData(data => {
                return {
                    ...data,
                    nameErr: "** This field is required."
                }
            })
        }


    }

    const addExchange = async () => {
        console.log(addExchangeData)
        if (addExchangeData.name !== "" && addExchangeData.name !== undefined) {
            if (sessionStorage.getItem("id") !== "" && sessionStorage.getItem("id") !== undefined && sessionStorage.getItem("id") !== null) {
                if (addExchangeData.link !== "" && addExchangeData.link !== undefined) {
                    setExchangeData(userData => {
                        return {
                            ...userData,
                            loaderAct1: true,
                        }
                    });
                    let data = qs.stringify({
                        'name': addExchangeData.name,
                        'link': addExchangeData.link,
                        'adminId': sessionStorage.getItem("id")
                    });
                    const response = await aracoinAPI(data, "POST", 'create-exchange');
                    if (response.success == true) {
                        setOpenForAdd(false);
                        Swal.fire({ text: response.msg, icon: 'success', background: 'white', confirmButtonText: false, showConfirmButton: false, timer: 3000 });
                        setAddExchangeData(userData => {
                            return {
                                ...userData,
                                name: "",
                                link: "",
                                nameErr: "",
                                linkErr: "",
                                loaderAct1: false
                            }
                        })

                    } else {
                        Swal.fire({ text: response.msg, icon: 'error', background: 'white', confirmButtonText: false, showConfirmButton: false, timer: 3000 });
                    }
                } else {
                    setAddExchangeData(data => {
                        return {
                            ...data,
                            linkErr: "** This field is required."
                        }
                    })
                }
            } else {
                Swal.fire({ text: "You are not a authorized user.", icon: 'warning', background: 'white', confirmButtonText: false, showConfirmButton: false, timer: 3000 });
            }

        } else {
            setExchangeData(data => {
                return {
                    ...data,
                    nameErr: "** This field is required."
                }
            })
        }
    }

    const logout = () =>{
        sessionStorage.removeItem("id");
        Navigate('/');
    }

    useEffect(() => {
        // Check session storage value
        const sessionValue = sessionStorage.getItem('id');
    
        // Redirect if value is undefined or null
        if (sessionValue === undefined || sessionValue === null) {
          Navigate('/'); // Replace '/login' with your login page route
        }
      }, []);


    return (
        <>
            <Modal
                onClose={handleClose}
                open={open}
                style={{
                    position: 'absolute',
                    border: '1px solid #fff',
                    boxShadow: '2px solid #fff',
                    height: 250,
                    borderRadius: 15,
                    width: 300,
                    margin: 'auto'
                }}
                className="clasmodel"
            >
                <div className="FormBOx">
                    <div>
                        <label>Name:</label>
                        <input type="text" value={exchangeData.name} onChange={handleOnChangeName} onKeyUp={submitOnEnter} />
                    </div>
                    <div>
                        <label>Link:</label>
                        <input type="text" value={exchangeData.link} onChange={handleOnChangeLink} onKeyUp={submitOnEnter} />
                    </div>
                    <div className="btnsubmit text-center">
                        {exchangeData.loaderAct ? <button>Processing....</button> : <button onClick={updateExchange}>Update</button>}
                    </div>
                </div>
            </Modal>
            <Modal
                onClose={handleCloseForAdd}
                open={openForAdd}
                style={{
                    position: 'absolute',
                    border: '1px solid #fff',
                    boxShadow: '2px solid #fff',
                    height: 250,
                    borderRadius: 15,
                    width: 300,
                    margin: 'auto'
                }}
                className="clasmodel"
            >
                <div className="FormBOx">
                    <div>
                        <label>Name:</label>
                        <input type="text" onChange={handleOnChangeNameInAdd} onKeyUp={submitOnEnter} />
                    </div>
                    <div>
                        <label>Link:</label>
                        <input type="text" onChange={handleOnChangeLinkInAdd} onKeyUp={submitOnEnter} />
                    </div>
                    <div className="btnsubmit text-center">
                        {addExchangeData.loaderAct1 ? <button>Processing....</button> : <button onClick={addExchange}>Add</button>}
                    </div>
                </div>
            </Modal>
            <section className="bannerSection">
                <div style={{ borderBottom: "1px solid #c7bfbf" }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark ">
                            <a className="navbar-brand" href="#">ARACOIN</a>
                            {/* <img className="logoimg" src="images/logo.png" /> */}
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                                <ul className="navbar-nav m-auto mt-2 mt-lg-0 ul-list">
                                    <li className="nav-item active">
                                        <Link className="nav-link active" to="/info">Exchange Info</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link " to="/smart">Smart Contract</Link>
                                    </li>
                                </ul>
                                <form className="form-inline my-2 my-lg-0">
                                    <button className="btn btn-outline-success my-2 my-sm-0" type="submit" onClick={logout}>Logout</button>
                                </form>
                            </div>
                        </nav>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <div className="row sec-alline pt-5">
                            <div className="col-md-10 m-auto">
                                <div className="head-text" style={{ textAlign: "right" }}>
                                    <a className="main-bttn" href="#" onClick={handleOpenforAdd}>Add</a>
                                </div>
                                <div style={{ overflow: "scroll" }}>
                                    <table className="bp4-html-table bp4-html-table-bordered bp4-interactive" border="1" style={{ width: "100%", marginTop: "20px" }}>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Name</th>
                                                <th>Link</th>
                                                <th>Action</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.slice(0, showItems).map((item, index) => (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td><a href={item.link}>{item.link}</a></td>
                                                    <td><a className="main-bttn1" href="#" onClick={() => handleOpen(item.name, item.link, item.id)}>Update</a></td>
                                                    <td><a className="main-bttn1" href="#" onClick={() => deleteExchange(item.id)}>Delete</a></td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>

                                </div>
                                <div className="d-flex justify-content-center mt-5" id='project-tor'>
                                    {items.length > 10 && (
                                        <button onClick={handleClick} className="main-bttn">
                                            {showAll ? 'View Less' : 'View More'}
                                        </button>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="">

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Index;
