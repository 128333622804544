import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { getInfo, ActiveNetwork, ContractAbi, ContractAddress, RPCURL } from "../Common";
import Swal from "sweetalert2";
import Web3 from "web3";
import { BigNumber } from "bignumber.js";

const Index = () => {
    const Navigate = useNavigate();
    const [account, setAccount] = useState(null);
    const [isLocked, setIsLocked] = useState(false);
    const [mintAmt, setMintAmt] = useState();
    const [showMintAmt, setShowMintAmt] = useState();
    // const [showTime, setShowTime] = useState({
    //     days: 0,
    //     hours: 0,
    //     minutes: 0,
    //     seconds: 0
    // });
    // const [mintTime, setMintTime] = useState();

    const getInfos = async () => {
        let info = await getInfo();
        // let time = convertSeconds(info[1]);
        setShowMintAmt(info[0] / 10 ** 18);
        // setShowTime(data => {
        //     return {
        //         ...data,
        //         days: time[0],
        //         hours: time[1],
        //         minutes: time[2],
        //         seconds:time[3]
        //     }
        // })
    }
    getInfos();

    // function convertSeconds(seconds) {
    //     let days = Math.floor(seconds / (24 * 60 * 60));
    //     let hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    //     let minutes = Math.floor(((seconds % (24 * 60 * 60)) % (60 * 60)) / 60);
    //     let remainingSeconds = seconds % 60;
    //     return [
    //         days,
    //         hours,
    //         minutes,
    //         remainingSeconds
    //     ];
    // }

    // function convertDateToSeconds(date) {
    //     var milliseconds = date.getTime();
    //     var seconds = Math.floor(milliseconds / 1000);
    //     return seconds;
    // }

    // const changeMintTime = async () => {
    //     try {
    //         if (account != null) {
    //             console.log(mintTime)
    //             const web3 = new Web3(window.ethereum || RPCURL);
    //             const tokenContract = new web3.eth.Contract(ContractAbi, ContractAddress);
    //             const date = new Date(mintTime);
    //             const time = convertDateToSeconds(date);
    //             const result = await tokenContract.methods.changeMintTime(time).send({ from: account });
    //             if (result) {
    //                 getInfos()
    //                 Swal.fire({ title: "<h5 style='color:white'>Mint time changed!</h5>", icon: "success", background: "#808080", showConfirmButton: false, timer: 4000 });
    //             } else {
    //                 Swal.fire({ title: "<h5 style='color:white'>Transaction Error!</h5>", icon: "error", background: "#808080", showConfirmButton: false, timer: 4000 });
    //             }
    //         } else {
    //             Swal.fire({ title: "<h5 style='color:white'>Connect your wallet!</h5>", icon: "warning", background: "#808080", showConfirmButton: false, timer: 4000 });
    //         }
    //     } catch (err) {
    //         Swal.fire({ title: "<h5 style='color:white'>Transaction Error!</h5>", icon: "error", background: "#808080", showConfirmButton: false, timer: 4000 });

    //     }
    // }

    const changeMintAmount = async () => {
        try {
            if (account != null) {
                console.log(mintAmt,"mintAmt")
                const web3 = new Web3(window.ethereum || RPCURL);
                const tokenContract = new web3.eth.Contract(ContractAbi, ContractAddress);
                const amt = BigNumber(
                    `${(mintAmt * 10 ** Number(18)).toFixed(0)}`
                  ).toFixed();
                const result = await tokenContract.methods.changeMintAmount(amt.toString()).send({ from: account });
                if (result) {
                    setShowMintAmt(mintAmt)
                    Swal.fire({ title: "<h5 style='color:white'>Mint amount changed!</h5>", icon: "success", background: "#808080", showConfirmButton: false, timer: 4000 });
                } else {
                    Swal.fire({ title: "<h5 style='color:white'>Transaction Error!</h5>", icon: "error", background: "#808080", showConfirmButton: false, timer: 4000 });
                }
            } else {
                Swal.fire({ title: "<h5 style='color:white'>Connect your wallet!</h5>", icon: "warning", background: "#808080", showConfirmButton: false, timer: 4000 });
            }
        } catch (err) {
            Swal.fire({ title: "<h5 style='color:white'>Transaction Error!</h5>", icon: "error", background: "#808080", showConfirmButton: false, timer: 4000 });

        }
    }

    const connectWallet = () => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            window.ethereum
                .request({ method: 'eth_requestAccounts' }) // Request access to the connected accounts
                .then(async (accounts) => {
                    await swichNetworkHandler();
                    setAccount(accounts[0]);
                    sessionStorage.setItem("adminAccount", accounts[0]);
                })
                .catch((error) => {
                    Swal.fire({ title: "<h5 style='color:white'>Error retrieving connected wallet address!</h5>", icon: "warning", background: "#808080", showConfirmButton: false, timer: 4000 });
                });
        } else {
            Swal.fire({ title: "<h5 style='color:white'>Please Install Metamask wallet!</h5>", icon: "warning", background: "#808080", showConfirmButton: false, timer: 4000 });
        }
    }

    const swichNetworkHandler = async () => {
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x" + ActiveNetwork.toString(16) }],
            });
        } catch (error) {
            console.log("ERROR", error);
            if (error.code === 4902) {
                Swal.fire({ title: "<h5 style='color:white'>Please add the sepolia network in your metamask wallet.</h5>", icon: "warning", background: "#808080", showConfirmButton: false, timer: 4000 });
                setAccount('');
            }
        }
    }

    const disconnectWallet = () => {
        sessionStorage.removeItem("adminAccount", account);
        setAccount('');
        setIsLocked(false)
    }

    useEffect(() => {
        const handleAccountsChanged = (accounts) => {
            if (accounts.length === 0) {
                setIsLocked(false)
                sessionStorage.removeItem("adminAccount", account);
                setAccount('');
            }
        };
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', handleAccountsChanged);
        }

        return () => {
            window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        };
    }, []);

    useEffect(() => {
        // Check session storage value
        const sessionValue = sessionStorage.getItem('id');
    
        // Redirect if value is undefined or null
        if (sessionValue === undefined || sessionValue === null) {
          Navigate('/'); // Replace '/login' with your login page route
        }
      }, []);

    useEffect(() => {
        const checkMetamask = async () => {
            const isLocked = await window.ethereum._metamask.isUnlocked();
            if (isLocked) {
                setIsLocked(true)
                setAccount(sessionStorage.getItem("adminAccount"));
            } else {
                setIsLocked(false)
                sessionStorage.removeItem("adminAccount", account);
                setAccount('');
            }
        };

        // Call the updateTokenBalance function immediately
        checkMetamask();

        // Set up an interval to periodically update the token balance (e.g., every 10 seconds)
        const interval = setInterval(checkMetamask, 1000); // Adjust the interval time as needed
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <section className="bannerSection">
                <div style={{ borderBottom: "1px solid #c7bfbf" }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark ">
                            <a className="navbar-brand" href="#">ARACOIN</a>
                            {/* <img className="logoimg" src="images/logo.png" /> */}
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                                <ul className="navbar-nav m-auto mt-2 mt-lg-0 ul-list">
                                    <li className="nav-item active">
                                        <Link className="nav-link" to="/info">Exchange Info</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link active" to="/smart">Smart Contract</Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <div className="row sec-alline pt-5">
                            <div className="col-md-10 m-auto align-right">
                                <div className="btntn">
                                    {account && isLocked ? <button onClick={disconnectWallet}>Disconnect</button> : <button onClick={connectWallet}>Connect Wallet</button>}
                                </div>
                            </div>
                            <div className="col-md-10 m-auto">
                                <div className="bsss">
                                    <div className="sdsd">
                                        <h3>Token Amount : {showMintAmt} ARA</h3>
                                        <input type="number" placeholder="Enter mint amount" onChange={(event) => setMintAmt(event.target.value)} />
                                        <button className="main-bttn" onClick={changeMintAmount}>Change</button>
                                    </div>
                                    {/* <div className="sdsd">
                                        <h3>Mint Time : {showTime.days + " " + ":" + showTime.hours + " "  + ":" + showTime.minutes + " " + ":" + showTime.seconds} </h3>
                                        <input type="datetime-local" onChange={(event) => setMintTime(event.target.value)} />
                                        <button className="main-bttn" onClick={changeMintTime}>Change</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>


            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="">

                            </div>
                        </div>
                    </div>
                </div>
            </section>







        </>
    );
};

export default Index;
